import React from "react"
import { graphql } from "gatsby"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Pagination from "../components/pagination";
import CompetitionListItem from "../components/competition-list-item";

const CompetitionList = ({ pageContext, data, location }) => {
  const {
    breadcrumb: { crumbs },
    currentPage,
    numPages
  } = pageContext

  return (
    <Layout>
      <SEO
          title="Nos jeux concours"
          description="Retrouvez la liste de nos jeux concours."
      />
      <div className="container list-page">
        <section className="section page-header">
          <Breadcrumb
            crumbs={crumbs}
            crumbSeparator=" > "
            crumbLabel="Jeux concours"
          />

          <h1 className="title is-1">Nos jeux concours</h1>
        </section>

        <section className="section page-content">
          <div id="competitions-list" className="columns is-multiline">
            {data.allNodeJeuConcours.nodes.map((node, index) => (
              <CompetitionListItem key={index} node={node} columnType={6} />
            ))}
          </div>

          <Pagination
            path={location.pathname}
            currentPage={currentPage}
            numPages={numPages}
          />
        </section>
      </div>
    </Layout>
  )
}

export default CompetitionList

export const query = graphql`
query jeuConcoursListQuery($skip: Int!, $limit: Int!) {
  allNodeJeuConcours(
    filter: {status: {eq: true}} 
    sort: {fields: field_date___value, order: DESC}
    limit: $limit, 
    skip: $skip
  ) {
    nodes {
      id
      title
      body {
        value
      }
      field_date {
        end_value(formatString: "DD/MM/YY", locale: "fr")
        value(formatString: "DD/MM/YY", locale: "fr")
      }
      path {
        alias
      }
      relationships {
        image: field_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 670, maxHeight: 400, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
}`
