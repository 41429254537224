import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import PropTypes from "prop-types";

const CompetitionListItem = ({ node, columnType, columnTypeWidescreen }) => (
  <div className={`column${columnTypeWidescreen ? ` is-${columnTypeWidescreen}-widescreen` : ''} is-${columnType}`}>
    <article className="competition">
      <div className="image">
        <Link to={node.path.alias}>
          <Img
            fluid={node.relationships.image.localFile.childImageSharp.fluid}
            alt={node.title}
          />
        </Link>
      </div>

      <div className="content">
        {/*<div className="date">*/}
        {/*  <span>{node.field_date.value} - {node.field_date.end_value}</span>*/}
        {/*</div>*/}

        <h3 className="title is-3">
          <Link to={node.path.alias}>{node.title}</Link>
        </h3>
      </div>
    </article>
  </div>
)

CompetitionListItem.propTypes = {
  node: PropTypes.object.isRequired,
  columnType: PropTypes.number,
  columnTypeWidescreen: PropTypes.number
}

CompetitionListItem.defaultProps = {
  columnType: 12,
}

export default CompetitionListItem
